<template>
  <div class="page-container d-flex flex-column">
    <div class="page-title">
      <h1>Försäkrade objekt</h1>
    </div>
    <div class="page-filter container" id="search-header">
      <form @submit.prevent="doSearch(false)" class="formSearch">
        <b-row class="align-items-center">
          <b-col class="col">
            <input
              name="power_to"
              type="text"
              class="form-control-main mt-1"
              v-model="search"
              placeholder="Sök"
            />
          </b-col>
          <b-col class="col-1 d-flex justify-content-end">
            <button type="submit" class="btn btn-primary" id="sok">Sök</button>
          </b-col>
        </b-row>
      </form>
      <b-row>
        <b-col class="col-10">
          <button class="btn btn-primary active align-center mr-2">
            <router-link class="nav-link" :to="{ name: 'Create' }">
              <i class="fas fa-plus mr-1"></i>
              Skapa nytt
            </router-link>
          </button>
          <button
            class="btn btn-outline-primary"
            id="kexs"
            @click="showAllButtonClick"
          >
            {{ showAllButtonText }}
          </button>
        </b-col>
        <b-col class="col-2 d-flex justify-content-end">
          <button
            v-on:click="sidebarFlag = !sidebarFlag"
            class="btn btn-outline-primary"
          >
            <i class="fas fa-filter mt-1 mr-1"></i>Alla filter
          </button>
        </b-col>
      </b-row>
    </div>

    <section
      class="sidebar-wrapper"
      :class="{
        overlay: sidebarFlag,
      }"
      v-click-outside="closeSideBar"
    >
      <div class="sidebar">
        <div class="sidebar-header">
          <button v-on:click="sidebarFlag = !sidebarFlag" class="close">
            <span>&times;</span>
          </button>
        </div>
        <h2 class="filter">Filtrera och sortera</h2>
      </div>
      <form @submit.prevent="doSearch(false)" id="formPopUP">
        <div
          v-for="(item, key) in options"
          v-bind:key="key"
          class="col-auto my-1"
        >
          <label class="mr-sm-2">{{ item.label }}</label>
          <br />
          <select
            v-if="item.choices"
            class="custom-select mr-sm-2"
            id="inlineFormCustomSelect"
            v-model="item.value"
            @change="newFilterValue($event.target.value, key)"
          >
            <option
              v-for="choice in item.choices"
              v-bind:key="choice.value"
              v-bind:value="choice.value"
            >
              {{ choice.display_name }}
            </option>
          </select>
          <input
            v-else
            :id="key"
            :name="key"
            :type="item.type"
            class="form-sidebar"
            @change="newFilterValue($event.target.value, key)"
            v-model="item.value"
            :placeholder="item.label"
            style="display: inline; width: 100%"
          />
        </div>
        <br />
        <div class="col-auto my-1">
          <label class="mr-sm-2"><b>Sortering</b></label>
          <select
            class="custom-select mr-sm-2"
            id="inlineFormCustomSelect"
            v-model="sort"
          >
            <option
              v-for="field in sortFields"
              v-bind:key="field.value"
              v-bind:value="field.value"
            >
              {{ field.display_name }}
            </option>
          </select>
        </div>
        <br />
        <button id="createFilter" class="btn btn-primary-toggel">
          <i class="fas fa-file-export"> </i> Skapa filter
        </button>
        <br /><br />
      </form>
    </section>
    <div
      v-for="info in filterdInfo"
      v-bind:key="info.title"
      class="singel-blog"
    >
      <h2>{{ info.title }}</h2>
    </div>
  </div>
  <div class="d-flex flex-column pl-2 pr-2">
    <div class="table-container flex-grow-1">
      <b-table
        class="table-responsive"
        id="table-id"
        ref="table-ref"
        striped
        small
        scrolly
        hover
        responsive
        :items="items"
        :fields="fields"
        :busy="isLoading"
        scope="row"
        aria-controls="my-table"
      >
        <template #cell(id)="data">
          <div class="cell-id">
            <router-link :to="{ name: 'Details', params: { id: data.value } }">
              <button class="display" v-on:click="sidebarFlag = !sidebarFlag">
                <i class="far fa-eye"></i> Display
              </button>
            </router-link>
            {{ data.value }}
          </div>
        </template>
        <template #cell(municipality)="data">
          {{ data.value?.name }}
        </template>
        <template #cell(property_designation_municipality)="data">
          {{ data.value?.name }}
        </template>
        <template #cell(category_external)="data">
          {{
            data.value
              ? data.value.value + " - " + data.value.description
              : null
          }}
        </template>
        <template #cell(category_internal)="data">
          {{
            data.value
              ? data.value.value + " - " + data.value.description
              : null
          }}
        </template>
        <template #cell(facility_class)="data">
          {{ data.value?.description }}
        </template>
        <template #cell(wooden_building)="data">
          {{ data.value?.description }}
        </template>
        <template #cell(fire_alarm)="data">
          {{ data.value?.description }}
        </template>
        <template #cell(solar_cells)="data">
          {{ data.value?.description }}
        </template>
        <template #cell(form_of_insurance)="data">
          {{ data.value?.description }}
        </template>
        <template #cell(cultural_protection_type)="data">
          {{ data.value?.description }}
        </template>
        <template #cell(fire_protection)="data">
          {{ data.value?.description }}
        </template>
        <template #cell(burglar_alarm)="data">
          {{ data.value?.description }}
        </template>
        <template #cell(gross_floor_area_unit)="data">
          {{ data.value?.description }}
        </template>
        <template #cell(calculated_value)="data">
          {{ $helpers.returnCurrency(data.value) }}
        </template>
        <template #cell(premium)="data">
          {{ $helpers.returnCurrency(data.value) }}
        </template>
        <template #cell(calculated_value_content)="data">
          {{ $helpers.returnCurrency(data.value) }}
        </template>
        <template #cell(insurance_amount)="data">
          {{ $helpers.returnCurrency(data.value) }}
        </template>
        <template #cell(calculated_value_total)="data">
          {{ $helpers.returnCurrency(data.value) }}
        </template>
        <template #cell(first_risk)="data">
          {{ $helpers.returnCurrency(data.value) }}
        </template>
        <template #cell(specific_terms)="data">
          {{ returnPostingsString(data.value) }}
        </template>
        <template #cell(insurance_company_information)="data">
          {{ returnPostingsString(data.value) }}
        </template>
      </b-table>
    </div>
  </div>
  <div class="page-container d-flex flex-column">
    <b-list-group class="list mt-3">
      <h3 class="laddaNer">Ladda ner</h3>
      <b-list-group-item class="item">
        <button
          v-on:click="getExport('object')"
          :disabled="isExporting"
          class="btn btn-primary"
          id="objects-csv"
        >
          <span
            v-if="isExporting"
            class="spinner-border spinner-border-sm mr-2"
            role="status"
            aria-hidden="true"
          ></span>
          <i class="fas fa-file-export"></i> Exportera Objekt
        </button>
        <button
          v-if="isAdmin"
          :disabled="!changeDatesGiven || isExporting"
          v-on:click="getChangeExport()"
          class="btn btn-primary"
          id="changes-csv"
        >
          <i class="fas fa-file-export"></i> Exportera Historik
        </button>

        <br />
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { axiosBase } from "@/axios-api";
import { useToast } from "vue-toastification";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  watch: {
    activeObjectType(toObjectType) {
      if (toObjectType !== undefined) {
        this.doSearch();
      }
    },
  },
  data() {
    return {
      user: this.$store.getters.userDetail,
      sortFields: [
        { value: "name", display_name: "Populärnamn (A-Ö)" },
        { value: "-name", display_name: "Populärnamn (Ö-A)" },
        {
          value: "property_designation",
          display_name: "Fastighetsbeteckning (A-Ö)",
        },
        {
          value: "-property_designation",
          display_name: "Fastighetsbeteckning (Ö-A)",
        },
        { value: "group", display_name: "Gruppering av objekt (A-Ö)" },
        { value: "-group", display_name: "Gruppering av objekt (Ö-A)" },
        { value: "street_name", display_name: "Gatuadress (A-Ö)" },
        { value: "-street_name", display_name: "Gatuadress (Ö-A)" },
        { value: "street_number", display_name: "Gatunr (Ö-A)" },
        { value: "-street_number", display_name: "Gatunr (Ö-A)" },
        { value: "zip_code", display_name: "Postnummer (A-Ö)" },
        { value: "-zip_code", display_name: "Postnummer (A-Ö)" },
        { value: "city", display_name: "Stad (A-Ö)" },
        { value: "-city", display_name: "Stad (Ö-A)" },
      ],
      sort: "",
      info: [],
      search: "",
      sidebarFlag: false,
      items: [],
      fields: [],
      showAll: false,
      isExporting: false,
      options: {
        municipality: {
          label: "Kommun",
          type: "select",
          value: 0,
          choices: [{ value: 0, display_name: "Alla" }],
        },
        name: { label: "Populärnamn", type: "text", choices: null },
        property_designation: {
          label: "Fastighetsbeteckning",
          type: "text",
          value: "",
          choices: null,
        },
        valid_on: {
          label: "Försäkrat datum",
          type: "date",
          value: new Date().toISOString().slice(0, 10),
          choices: null,
        },
        coord_exist: {
          label: "Koordinater",
          type: "select",
          value: 0,
          choices: [
            { value: 0, display_name: "Alla" },
            { value: "False", display_name: "Har Koordinater" },
            { value: "True", display_name: "Saknar Koordinater" },
          ],
        },
        changes_from: {
          label: "Har ändrats från",
          type: "date",
          value: "",
          choices: null,
        },
        changes_to: {
          label: "Har ändrats till",
          type: "date",
          value: "",
          choices: null,
        },
      },
      inputParams: {},
      currentPage: 1,
      hasNext: true,
      isLoading: false,
    };
  },
  async created() {
    this.inputParams["valid_on"] = new Date().toISOString().slice(0, 10);
    await this.getOptions();
    this.checkUser();
    await this.getSavedUrl();
    await this.doSearch(false);
  },
  async mounted() {
    const tableScrollBody = this.$refs["table-ref"].$el;
    /* Consider debouncing the event call */
    tableScrollBody.addEventListener(
      "scroll",
      this.$helpers.debounce(this.onScroll, 100)
    );
  },
  beforeUnmount() {
    /* Clean up just to be sure */
    const tableScrollBody = this.$refs["table-ref"].$el;
    tableScrollBody.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    async getSavedUrl() {
      let url = sessionStorage.getItem("get_url");
      if (url) {
        if (url.indexOf("valid_on") === -1) {
          delete this.inputParams["valid_on"];
        }
        let filter_params = url.split("&");
        let filter;
        this.options.valid_on.value = null;
        for (let i = 1; i < filter_params.length - 1; i++) {
          filter = filter_params[i].split("=");
          if (filter[0] === "search") {
            this.search = filter[1];
          } else if (filter[0] === "ordering") {
            this.sort = filter[1];
          } else if (filter[0] === "is_changed") {
            let change_dates = filter[1].split("%2C");
            this.options.changes_from.value = change_dates[0];
            this.options.changes_to.value = change_dates[1];
            this.inputParams["is_changed"] = filter[1];
            this.inputParams["changes_from"] = change_dates[0];
            this.inputParams["changes_to"] = change_dates[1];
          } else if (filter[0] !== "object_type") {
            if (filter[0] === "name__icontains") {
              this.options.name.value = decodeURIComponent(filter[1]);
              this.inputParams["name"] = this.options.name.value;
            } else if (filter[0] === "property_designation__icontains") {
              this.options.property_designation.value = decodeURIComponent(
                filter[1]
              );
              this.inputParams["property_designation"] =
                this.options.property_designation.value;
            } else if (
              filter[0] === "coordinate_lat__isnull" ||
              filter[0] === "coordinate_lon__isnull"
            ) {
              this.options.coord_exist.value = filter[1];
              this.inputParams[filter[0].slice(0, 14)] = filter[1];
            } else if (filter[0] !== "ordering") {
              this.options[filter[0]].value = filter[1];
              this.inputParams[filter[0]] = filter[1];
            }
          }
        }
      }
    },
    returnPostingsString(postings) {
      let postings_string = "";
      for (let i = 0; i < postings.length; i++) {
        postings_string += i + 1 + ". " + postings[i].text + "\n";
      }
      return postings_string;
    },
    checkUser() {
      if (this.user.group === "EXTERN") {
        this.options.municipality.choices.splice(0, 1);
        this.options.municipality.value =
          this.options.municipality.choices[0].value;
      } else if (this.user.group === "INTERMEDIARY") {
        this.options.municipality.choices.splice(0, 1);
        this.options.municipality.value =
          this.options.municipality.choices[0].value;
        this.inputParams["municipality"] = this.options.municipality.value;
      }
    },
    getChangeExport() {
      if (this.checkChangeDates()) {
        this.getExport("log");
      }
    },
    checkChangeDates() {
      if (!this.changeDatesGiven && !this.noChangeDatesGiven) {
        this.toast.error("Var vänlig fyll i både ändrat från/till");
        return false;
      } else {
        if (this.inputParams["is_changed"]) {
          delete this.inputParams["is_changed"];
        }
        if (this.changeDatesGiven) {
          decodeURIComponent(
            (this.inputParams["is_changed"] = this.inputParams[
              "changes_from"
            ].concat(",", this.inputParams["changes_to"]))
          );
        }
        return true;
      }
    },
    newFilterValue(new_value, key) {
      if (this.options[key].choices) {
        if (new_value === "0") {
          if (key === "coord_exist") {
            delete this.inputParams["coordinate_lat"];
            delete this.inputParams["coordinate_lon"];
          } else {
            delete this.inputParams[key];
          }
        } else {
          if (key === "coord_exist") {
            this.inputParams["coordinate_lat"] = new_value;
            this.inputParams["coordinate_lon"] = new_value;
          } else {
            this.inputParams[key] = new_value;
          }
        }
      } else {
        if (!new_value.length) {
          delete this.inputParams[key];
        } else {
          this.inputParams[key] = new_value;
        }
      }
    },
    encodeData(data) {
      return Object.keys(data)
        .map(function (key) {
          if (
            key === "municipality" ||
            key === "valid_on" ||
            key === "is_changed"
          ) {
            return [key, data[key]].map(encodeURIComponent).join("=");
          } else if (key === "coordinate_lat" || key === "coordinate_lon") {
            return [key, data[key]].map(encodeURIComponent).join("__isnull=");
          } else if (key !== "changes_from" && key !== "changes_to") {
            return [key, data[key]]
              .map(encodeURIComponent)
              .join("__icontains=");
          }
        })
        .filter(Boolean)
        .join("&");
    },
    async getOptions() {
      await axiosBase
        .get("api/v1/municipality/")
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            this.options.municipality.choices.push({
              value: response.data[i].id,
              display_name: response.data[i].name,
            });
          }
        })
        .catch(() => {});
    },
    async getExport(type) {
      this.isExporting = true;
      await axiosBase({
        url: `api/v1/insuranceobject/download_list/${type}/` + this.getURL(),
        method: "GET",
        responseType: "blob",
        timeout: 300000,
      })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          let filename =
            this.user.group.toLowerCase() +
            "_" +
            (type === "object" ? (this.showAll ? "extended" : "basic") : type) +
            "_export" +
            "_" +
            this.activeObjectType.description.toLowerCase() +
            ".xlsx";
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          this.toast.success("Excel skapad");
        })
        .catch(async (error) => {
          let errorResponse = await error.response.data.text();
          const errorObj = JSON.parse(errorResponse);
          this.handleError(errorObj);
        })
        .finally(() => {
          this.isExporting = false;
        });
    },
    async doSearch(isScroll) {
      if (this.checkChangeDates()) {
        this.sidebarFlag = false;
        this.isLoading = true;
        Array.prototype.extend = function (other_array) {
          // https://stackoverflow.com/questions/27896936/adding-methods-to-array-prototype-so-i-can-apply-it-to-array-object-but-it-is-gi
          /* You should include a test to check whether other_array really is an array */
          other_array.forEach(function (v) {
            this.push(v);
          }, this);
        };
        if (!isScroll) this.currentPage = 1; // Reset if search/checkbox or filter
        await axiosBase
          .get(`api/v1/insuranceobject/` + this.getURL())
          .then((response) => {
            this.hasNext = !!response.data.next;
            if (isScroll) {
              this.items.extend(response.data.results);
            } else {
              this.items = response.data.results;
            }
            this.fields = response.data.metadata.map((x) => {
              x.key = x.name;
              return x;
            });
          })
          .catch(() => {});
        this.isLoading = false;
      }
    },
    getURL() {
      let url = "";
      // filter(boolean) only adds strings with values ["hi","","po"] will be hi&po in below
      url = [
        !this.showAll ? "basic=true" : "", // add showAll if exists
        this.sort ? "ordering=" + this.sort : "",
        this.search ? "search=" + this.search : "",
        "object_type=" + this.activeObjectType?.id,
        this.encodeData(this.inputParams),
        "page=" + this.currentPage,
      ]
        .filter(Boolean)
        .join("&");
      url = url ? "?" + url : ""; // if url has value add & before
      sessionStorage.setItem("get_url", url);
      return url;
    },
    onScroll(event) {
      // Add pagination function on scroll to table
      if (
        event.target.scrollTop + event.target.clientHeight >=
        event.target.scrollHeight - 100
      ) {
        if (!this.isLoading && this.hasNext) {
          this.currentPage += 1;
          this.doSearch(true);
        }
      }
    },
    showAllButtonClick() {
      this.showAll = !this.showAll;
      this.doSearch(false);
    },
    closeSideBar(e) {
      if (e.target.innerText !== "Alla filter") this.sidebarFlag = false;
    },
    handleError(error) {
      if (error) {
        // Request made and server responded
        let message = "";
        for (const property in error) {
          if (error[property].constructor == Object) {
            error[property].forEach(function (item) {
              message += item;
            });
          } else {
            message += error[property];
          }
        }
        this.toast.error(message);
      } else {
        // The request was made but no response was received
        this.toast.error("Något gick fel, vänligen försök igen senare");
      }
    },
  },
  computed: {
    filterdInfo: function () {
      return this.info.filter((info) => {
        return info.title.match(this.search);
      });
    },
    isAdmin: function () {
      return this.user.roles.kommunassurans;
    },
    changeDatesGiven: function () {
      return (
        this.$helpers.inDict("changes_from", this.inputParams) &&
        this.$helpers.inDict("changes_to", this.inputParams)
      );
    },
    noChangeDatesGiven: function () {
      return (
        !this.$helpers.inDict("changes_from", this.inputParams) &&
        !this.$helpers.inDict("changes_to", this.inputParams)
      );
    },
    activeObjectType: function () {
      return this.$store.getters.getActiveObjectType;
    },
    showAllButtonText: function () {
      return this.showAll ? "Visa färre kolumner" : "Visa alla kolumner";
    },
  },
};
</script>
<style scoped>
.page-header {
  margin-bottom: 15px;
}

#search-header {
  max-width: inherit;
}
</style>
<style>
#formPopUP {
  margin-right: 10px;
}
#objects-csv {
  margin-left: 6px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
#changes-csv {
  margin-left: 6px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
input#kexs.check.form-check-input {
  margin-left: -23px;
  margin-top: 7px;
}

button {
  display: inline-block;
}

.form-control-main {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.table > :not(:last-child) > :last-child > * {
  padding: 0.9rem !important;
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

tr {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-sm td,
.table-sm th {
  padding-top: 0 !important;
  min-width: 110%;
}

.filter {
  font-size: 30px;
}

.btn-outline-primary {
  color: #0176ba !important;
  border-color: #0176ba !important;
  font-weight: 100 !important;
}

.btn-outline-primary:hover {
  color: white !important;
  background-color: #0176ba !important;
}

#createFilter {
  margin-left: 16px !important;
  background-color: #0176ba;
  color: white;
  margin-top: -11px;
}

#sok {
  width: 95%;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin-bottom: 13px !important;
}

table {
  max-width: 100% !important;
}
.my-1 {
  margin-bottom: 0.1rem !important;
  padding-top: -0.9rem;
}

#show-data {
  max-width: 80%;
  margin: -15px auto;
}

ul {
  margin-top: 0 !important;
}

td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
}
td:hover {
  overflow: visible;
  white-space: normal;
  max-width: 250px;
}

select {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: -1.625rem 9.75rem -1.625rem 0.25rem;
}
.form-sidebar {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: -1.625rem 9.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding-left: 0.3rem !important;
}

.custom-select {
  padding-left: 0.3rem !important;
}

.fa-eye:before {
  content: "\f06e";
  padding-right: 6px;
}

.table {
  max-width: 100%;
  overflow: scroll;
  z-index: -1;
  background-color: #fff;
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
  border-color: rgba(223, 225, 229, 0);
}

.sidebar-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  width: 0;
  height: 100vh;
  overflow-x: hidden;
  background-color: white;
  transition: all 0.3s;
}

.overlay {
  width: 300px;
  background-color: #fff;
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
  border-color: rgba(223, 225, 229, 0);
  z-index: 12;
}

.sidebar {
  margin-left: 15px;
  margin-top: 15px;
}

.sidebar-header {
  display: flex;
  align-items: flex-start;
}

.sidebar-header .close {
  margin: -30px 2px -15px auto;
  padding: 15px;
}

.btn-primary {
  color: #fff;
  background-color: #0176ba !important;
  border-color: #0176ba !important;
  border-radius: 5px !important;
  padding: 6px 20px;
  border-style: none;
  font-weight: 200 !important;
}

.close {
  line-height: 40px !important;
}

.item {
  margin-bottom: 40px;
}
#table-id {
  height: 700px !important;
}

.table thead th {
  position: sticky;
  top: -2px;
  z-index: 1;
  background: rgba(1, 118, 186, 1);
  color: white;
  font-size: 15px;
}
.table tbody th {
  position: sticky;
  left: 0;
}

.table-container {
  margin-top: 3rem;
  width: 100%;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: -2rem !important;
}
#LoggaIn {
  padding-left: 3px;
}
.cell-id {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.display {
  float: left !important;
  padding: 4px !important;
  background: rgba(1, 118, 186, 1);
  color: white;
  display: flex;
  border-radius: 2px;
  font-size: 13px;
  margin: auto !important;
  border: none;
}
</style>
