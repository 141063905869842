<template>
  <div class="page-container">
    <div class="page-title">
      <h1>Skapa nytt objekt</h1>
    </div>
    <div class="col-xs-6 form-background">
      <form @submit.prevent class="needs-validation" novalidate>
        <div v-for="(section, index) in sections" v-bind:key="section">
          <FormSection
            :section="section"
            :index="index"
            @updatedValue="updated"
          />
        </div>
      </form>
    </div>
    <footer-container
      :isNew="true"
      :hasPendingChanges="false"
      :isUpdate="false"
      :isModified="false"
      :isApprove="false"
      :isValid="isValid"
      @doValidation="prepAndValidate"
      @execute="doCreate"
    />
  </div>
</template>

<script>
import FooterContainer from "../components/FooterContainer.vue";
import Base from "@/views/InsuranceInputBase.vue";
import FormSection from "../components/form/FormSection.vue";

export default {
  extends: Base,
  components: {
    FooterContainer,
    FormSection,
  },
  async mounted() {
    this.initBase();
    this.coord = { lat: 0, lon: 0 };
    await this.getStructure();
    this.setInitialMunicipality();
    this.setupCustomFormEvents();
  },
  computed: {
    ctx: function () {
      return {
        formOfInsurance: this.formOfInsurance,
        new: true,
        notApproved: false,
        isIntern: this.isIntern,
        item: this.item,
      };
    },
  },
  methods: {
    async prepAndValidate(doWarning) {
      this.ensureDefaultValuesAreSubmitted();
      this.isValid = this.validate(doWarning);
    },
    ensureDefaultValuesAreSubmitted() {
      for (let key in this.formList) {
        let value = this.formList[key].value;
        if (!this.$helpers.inDict(key, this.submitForm)) {
          this.submitForm[key] = value;
        }
      }
    },
    async doCreate(data) {
      this.submitForm["send_to"] = data.send_to;
      this.submitForm["type_of_change"] = data.typeOfChange;
      this.submitForm["object_type"] = this.objectType.id;

      const props = {
        id: null,
        sendEmail: this.isIntern,
        successMessage: this.getSuccessToastMessage(data.send_to),
      };

      await this.sendRequest(props);
    },
    getSuccessToastMessage(send_to) {
      if (this.isIntern) return "Nytt objekt har skapat";
      else if (send_to === "to_intermediary")
        return "Begäran om nytt objekt har skickats till Förmedlare";
      return "Begäran om nytt objekt har skickats till Admin";
    },
    setInitialMunicipality() {
      if (this.formList.municipality.options.length === 1) {
        this.formList["municipality"].value =
          this.formList.municipality.options[0].value;
        this.updatePropertyValue(
          "municipality",
          this.formList["municipality"].value
        );
      }
    },
    setupCustomFormEvents() {
      this.changeHandler.setUser(this.user);
      this.changeHandler.register(
        this.changeHandler.set_DynamicPropertyValues_ForAdmin,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_DynamicPropertyValues_ForUser,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_DatePickerRestriction_ForUser,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_PropertyDesignationMunicipality,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_PropertyDesignationMunicipality,
        "update"
      );
      this.changeHandler.register(
        this.changeHandler.set_RequestedInsuranceFromDate,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_RequestedFormOfInsurance_Visible,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_RequestedFormOfInsurance_Required,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_FormOfInsurance_Visible,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_FirstRisk_BasedOn_FormOfInsurance,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_FirstRisk_BasedOn_FormOfInsurance,
        "update"
      );
      this.changeHandler.register(
        this.changeHandler.set_InsuranceAmount_BasedOn_FormOfInsurance,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_InsuranceAmount_BasedOn_FormOfInsurance,
        "update"
      );
      this.changeHandler.register(
        this.changeHandler.set_RequestedInsuranceToDate_Placeholder,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_RequestedInsuranceToDate_Placeholder,
        "update"
      );
      this.changeHandler.register(
        this.changeHandler
          .set_RequestedInsuranceToDate_BasedOn_InsuranceFromDate,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler
          .set_Required_InsuranceToDate_BasedOn_RequestedInsuranceToDate,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_InsuranceFromDate_Placeholder,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_InsuranceToDate_Placeholder,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_InsuranceToDate_Placeholder,
        "update"
      );
      this.changeHandler.register(
        this.changeHandler.set_CreatedBy_Placeholder,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_CreatedOn_Value,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_UW_Value,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_UWDecisionDate_Value,
        "initialize"
      );
      this.changeHandler.handleEvent("initialize", this.formList);
    },
  },
};
</script>

<style>
#create {
  margin-left: 15px;
}

@media (min-width: 1400px) {
  .multi-collapse {
    background-color: lightgreen;
  }
}
/*knapp*/

select#intermediary.form-Mottagare {
  margin-top: 10px;
}

.btn,
.btn-success {
  height: 38px;
  margin-top: 4px;
}

div#SkickaChangeFörmedlare.card.card-body {
  width: 100%;
  border: none;
  border-radius: 0;
}

div.form-check.form-Mottagare {
  padding: 0 !important;
  margin-left: 30px;
}

.bottom {
  margin-top: 28px;
}

.card-body {
  width: 50%;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
  margin-right: 2px;
}

.multi-collapse {
  overflow: auto;
}

.form-row > .form-row [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.form-row-inside {
  display: flex;
  flex-direction: row;
}

.form-row {
  margin-bottom: 0rem;
  padding: 0.375rem 0.75rem;
  display: flex;
}
</style>
