<template>
  <div class="page-container">
    <div class="page-title">
      <h1>{{ title }}</h1>
    </div>
    <div class="col-xs-6 form-background">
      <form @submit.prevent class="needs-validation" novalidate>
        <div v-for="(section, index) in sections" v-bind:key="section">
          <FormSection
            :section="section"
            :index="index"
            @updatedValue="propertyUpdated"
          />
        </div>
      </form>
    </div>
    <confirmation-modal
      v-if="showDateModal"
      header="Bekräfta avslutsdatum för försäkringen"
      body="Du har angett ett avslutsdatum för försäkringen som ligger bakåt i tiden, vänligen bekräfta att du vill fortsätta."
      @close-modal-event="closeDateModal"
    />
    <footer-container
      :isNew="false"
      :hasPendingChanges="pendingChanges"
      :isUpdate="true"
      :isApprove="false"
      :isModified="noOfChanges > 0"
      :isValid="isValid"
      @doValidation="doValidation"
      @execute="doUpdate"
    />
  </div>
</template>

<script>
import FooterContainer from "../components/FooterContainer.vue";
import Base from "@/views/InsuranceInputBase.vue";
import FormSection from "../components/form/FormSection.vue";
import ConfirmationModal from "../components/ConfirmationModal.vue";

export default {
  extends: Base,
  components: {
    FooterContainer,
    FormSection,
    ConfirmationModal,
  },
  data() {
    return {
      changeMaster: 0,
      changeMasterStatus: "",
      pendingChanges: false,
      showDateModal: false,
    };
  },
  mounted() {
    this.initBase();
    this.initialize();
  },
  computed: {
    ctx: function () {
      return {
        isIntern: this.isIntern,
        pendingChanges: this.pendingChanges,
        item: this.item,
        formOfInsurance: this.formOfInsurance,
      };
    },
    title: function () {
      if (this.formList.id)
        return this.formList.id.value + " - " + this.formList.name.value;
      return " ";
    },
    noOfChanges: function () {
      return Object.keys(this.submitForm).length;
    },
  },
  methods: {
    async initialize() {
      await this.loadItem();
      await this.getStructure();
      this.buildCompleteObjectInformation();
      this.initializePendingChanges();
      this.checkItemAccess();
      this.setupCustomFormEvents();
    },
    async doValidation(doWarning) {
      this.isValid = this.validate(doWarning);
    },
    async doUpdate(data) {
      this.submitForm["send_to"] = data.send_to;
      if (!this.hasPendingChanges)
        this.submitForm["type_of_change"] = data.typeOfChange;

      const props = {
        id: this.$route.params.id,
        sendEmail: this.isIntern,
        successMessage: this.getSuccessToastMessage(data.send_to),
      };

      await this.sendRequest(props);
    },
    getSuccessToastMessage(send_to) {
      if (this.isIntern) return "Ändringar genomförda";
      else if (send_to === "to_intermediary")
        return "Ändringsbegäran skickad till Förmedlare";
      return "Ändringsbegäran skickad till Admin";
    },
    checkItemAccess() {
      if (this.user.roles.municipality && this.pendingChanges) {
        this.toast.error("Detta objekt väntar på godkännande");
        this.$router.push({
          name: "Details",
          params: { id: this.item.id },
        });
      }
      if (
        this.user.roles.intermediary &&
        this.changeMasterStatus === "at_admin"
      ) {
        this.toast.error("Detta objekt befinner sig hos admin för godkännande");
        this.$router.push({
          name: "Details",
          params: { id: this.item.id },
        });
      }
    },
    propertyUpdated(key, new_value) {
      this.updated(key, new_value);
      if (key === "insurance_to_date") this.checkIfDateIsEarlier(new_value);
    },
    checkIfDateIsEarlier(inputDate) {
      const today = new Date().toISOString().split("T")[0];
      if (inputDate < today) this.showDateModal = true;
    },
    closeDateModal(accepted) {
      if (!accepted) {
        this.updated("insurance_to_date", this.item.insurance_to_date);
        this.formList.insurance_to_date.value =
          this.item.insurance_to_date ?? null;
      }
      this.showDateModal = false;
    },
    setupCustomFormEvents() {
      this.changeHandler.setUser(this.user);
      this.changeHandler.register(
        this.changeHandler.set_DynamicPropertyValues_ForAdmin,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_DynamicPropertyValues_ForUser,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_DatePickerRestriction_ForUser,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_RequestedFormOfInsurance_Visible,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_RequestedFormOfInsurance_Required,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_FormOfInsurance_Visible,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_FirstRisk_BasedOn_FormOfInsurance,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_FirstRisk_BasedOn_FormOfInsurance,
        "update"
      );
      this.changeHandler.register(
        this.changeHandler.set_InsuranceAmount_BasedOn_FormOfInsurance,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_InsuranceAmount_BasedOn_FormOfInsurance,
        "update"
      );
      this.changeHandler.register(
        this.changeHandler.set_InsuranceFromDate_Placeholder,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_RequestedInsuranceToDate_Placeholder,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler
          .set_RequestedInsuranceToDate_BasedOn_InsuranceFromDate,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_RequestedInsuranceToDate_Placeholder,
        "update"
      );
      this.changeHandler.register(
        this.changeHandler.set_InsuranceToDate_Placeholder,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_InsuranceToDate_Placeholder,
        "update"
      );
      this.changeHandler.register(
        this.changeHandler
          .set_RequestedInsuranceToDate_BasedOn_InsuranceFromDate,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler
          .set_Required_InsuranceToDate_BasedOn_RequestedInsuranceToDate,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_UW_Value,
        "initialize"
      );
      this.changeHandler.register(
        this.changeHandler.set_UWDecisionDate_Value,
        "initialize"
      );
      this.changeHandler.handleEvent("initialize", this.formList);
    },
  },
};
</script>

<style>
#create {
  margin-left: 15px;
}

@media (min-width: 768px) {
  #edit {
    padding-right: 5px;
  }
}

@media (min-width: 1400px) {
  .multi-collapse {
    background-color: lightgreen;
  }
}
/*knapp*/

div.ol-unselectable ol-layers {
  margin-top: 22px;
}
div.ol-zoom ol-unselectable ol-control {
  margin-top: 22px;
}
.gul {
  padding-right: 20px;
  margin-left: 17%;
}
b#changelock.gul {
  margin-left: 102px;
}
select#intermediary.form-Mottagare {
  margin-top: 10px;
}

.grön {
  /*padding-left: 30%;*/
  padding-right: 20px;
}

.ändringTop {
  margin-top: -16px;
}

#NoChangeInput {
  font-size: 12px;
  margin-left: 29px;
}

#NoNewInput {
  font-size: 12px;
  margin-left: 3px;
}

.btn,
.btn-success {
  height: 38px;
  margin-top: 4px;
}

.outline-success {
  border: 3px solid black;
}

div#SkickaChangeFörmedlare.card.card-body {
  width: 100%;
  border: none;
  border-radius: 0;
}

div#SkickaNewFörmedlare.card.card-body {
  width: 100%;
  border: none;
}

div.form-check.form-Mottagare {
  padding: 0 !important;
  margin-left: 30px;
}

#hittaKoordinator {
  margin-top: 32px;
}

#Förmedlarhus {
  margin-bottom: 2px;
}

.card-body {
  width: 50%;
}

.table > :not(caption) > * > * {
  padding: 0.3rem 0.3rem 0.3rem !important;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
  margin-right: 2px;
}

.form-select {
  width: 100% !important;
  overflow: auto;
}

#skicka {
  margin-top: 20px;
}

.multi-collapse {
  overflow: auto;
}

.input-group-prepend {
  margin-bottom: 0.4rem !important;
}

.btn-icon {
  height: 14px;
  margin: 0 auto;
  margin-top: -3px;
}

.form-row > .form-row [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.form-row-inside {
  display: flex;
  flex-direction: row;
}

/*knapp till kommuner */
#edit {
  margin: 10px 0 10px 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.object-header {
  display: flex;
  align-items: center;
}

.crateContainer {
  border-top: 1px solid #ced4da;
  background-color: white;
  position: sticky;
  bottom: 0;
}

.change-button-container {
  display: flex;
  justify-content: space-between;
}

.form-row {
  margin: 0 auto;
  display: flex;
}

div.form-row {
  margin-bottom: 0rem;
  padding: 0.375rem 0.75rem;
}
.Form-control {
  margin-top: 0 !important;
  margin-bottom: 15px;
  width: 100% !important;
  display: block;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0 !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
}

.input-group-text {
  background-color: #e9ecef;
  border: 0 solid #ced4da !important;
  border-radius: 0.25rem;
}

.card-edit {
  max-width: 35%;
  padding-right: 12px;
  margin-bottom: 40px;
  padding-top: 14px;
}
</style>
