<template>
  <div class="page-container">
    <div class="page-title">
      <h1>{{ $route.params.id }} - {{ this.name }}</h1>
    </div>
    <div>
      <p style="color: red" v-if="hasPendingChanges">
        <b>har en pågående ändringsbegäran</b>
      </p>
    </div>
    <b-row>
      <b-col
        col
        cols="8"
        class="scrollable"
        v-bind:class="displayChanges ? 'scrollable-print-changes' : ''"
      >
        <div
          class="section w-100"
          v-for="(section, key) in columns"
          :id="key"
          v-bind:key="key"
        >
          <div
            class="section-header list-group-item"
            data-bs-toggle="collapse"
            :href="'#toggle-section-' + key"
            role="button"
            aria-expanded="true"
            :aria-controls="'toggle-section-' + key"
          >
            <h3 class="section-title">
              <i class="fa fa-solid fa-plus"></i>
              <i class="fa fa-solid fa-minus"></i>
              {{ section.label }}
            </h3>
          </div>
          <div class="section-body collapse show" :id="'toggle-section-' + key">
            <div class="d-flex flex-wrap col-12">
              <template
                v-for="(object, key) in section.fields"
                :id="key"
                v-bind:key="key"
              >
                <div
                  class="list-group-item"
                  :class="getFieldClass(object)"
                  :id="key"
                  v-show="
                    object.name !== 'requested_form_of_insurance' ||
                    (object.name == 'requested_form_of_insurance' &&
                      (this.user.group == 'INTERN' || !hasFormOfInsurance))
                  "
                >
                  <div class="item-data" style="white-space: pre">
                    <strong class="color">{{ object.label }}</strong>
                    <!-- eslint-disable-next-line -->
                    <div v-if="!Array.isArray(object.value)" class="item-text">
                      {{ getDisplayValue(object.value) }}
                    </div>
                    <div
                      v-else
                      v-for="post in object.value"
                      v-bind:key="post.id"
                    >
                      <i class="posting"
                        >[{{ post.created_by.username }}
                        {{ post.created_on }}]</i
                      >
                      <p class="posting">{{ post.text }}</p>
                    </div>
                  </div>
                </div>
                <div v-if="object.force_linebreak" class="col-12"></div>
              </template>
            </div>
          </div>
        </div>
      </b-col>
      <b-col>
        <div class="left-menu-container">
          <div class="list-group list-menu">
            <a
              v-if="canEdit"
              id="andtaObjekt"
              class="list-group-item list-group-item-action"
            >
              <router-link :to="{ name: 'Update' }">
                <i class="fas fa-exchange-alt"></i>
                Ändra Objekt
              </router-link>
            </a>

            <a
              @click="continueToCancelModal()"
              class="list-group-item list-group-item-action"
              v-if="!hasPendingChanges"
            >
              <i class="fa fa-trash"></i>
              Avsluta försäkringsskydd
            </a>

            <a
              @click="toggleChanges()"
              class="list-group-item list-group-item-action"
            >
              <i class="fas fa-database"></i>
              Visa ändringar
            </a>
            <a class="list-group-item list-group-item-action" @click="getPdf()">
              <i class="fas fa-file-pdf"></i> Generera PDF
            </a>
          </div>
          <div v-show="coord.lat" class="smallMap">
            <Map v-bind:coord_in="coord"></Map>
          </div>
        </div>
      </b-col>
    </b-row>
    <section
      class="sidebar-wrapper"
      v-bind:class="displayChanges ? 'overlay' : ''"
    >
      <div class="sidebar">
        <div class="sidebar-header">
          <button v-on:click="toggleChanges" class="close">
            <span>&times;</span>
          </button>
        </div>
        <h2 class="changes">
          {{ $route.params.id }} {{ this.name }} - Historiska Ändringar
        </h2>
        <div class="column is-6">
          <table class="table is-full">
            <thead>
              <tr>
                <th>Fält</th>
                <th>Från</th>
                <th>Till</th>
                <th>När</th>
                <th>Av</th>
                <th>Godkänd</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="change in changes" v-bind:key="change.id">
                <td class="text-wrap">{{ change.field_label }}</td>
                <td class="text-wrap">{{ change.old_value }}</td>
                <td class="text-wrap">{{ change.new_value }}</td>
                <td class="text-wrap">{{ change.created_on }}</td>
                <td class="text-wrap">{{ change.created_by.username }}</td>
                <td class="text-wrap">
                  {{
                    change.change_master.status === "approved"
                      ? "Ja"
                      : "Under utredning"
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
  <cancel-modal
    v-if="showCancelModal"
    :objectDataId="objectData.id"
    @close-modal-event="modalClosed"
  />
</template>

<script>
import { axiosBase } from "@/axios-api";
import Map from "@/components/Map";
import CancelModal from "@/components/CancelModal.vue";
import { useToast } from "vue-toastification";

export default {
  data() {
    return {
      user: this.$store.getters.userDetail,
      objectData: {},
      hasPendingChanges: false,
      displayChanges: false,
      changes: [],
      columns: [],
      showCancelModal: false,
      name: "",
      coord: {
        lat: 0,
        lon: 0,
      },
    };
  },
  components: {
    Map,
    CancelModal,
  },
  computed: {
    canEdit: function () {
      return this.user.canEdit;
    },
    hasFormOfInsurance() {
      for (let section of this.columns) {
        for (let field of section.fields) {
          if (field.name === "form_of_insurance") {
            return !!field.value;
          }
        }
      }
      return "";
    },
    activeObjectType: function () {
      return this.$store.getters.getActiveObjectType;
    },
  },
  methods: {
    getDisplayValue(value) {
      if (!value) return "\xa0";
      if (value === true) return "Ja";
      if (value === false) return "Nej";
      return value;
    },
    toggleChanges() {
      this.displayChanges = !this.displayChanges;
    },
    setInsuranceDescription: function (data) {
      if (!data.insurance_from_date && !data.insurance_to_date) {
        data.insurance_from_date = "Ej försäkrad";
        data.insurance_to_date = "Ej försäkrad";
      } else if (data.insurance_from_date && !data.insurance_to_date) {
        data.insurance_to_date = "Tillsvidare";
      }
    },
    getFieldClass(field) {
      if (field.column_width === "") {
        return "col-12 mb-1";
      } else {
        return "col-" + field.column_width + " mb-1";
      }
    },
    continueToCancelModal() {
      if (
        this.$helpers.isValidDate(this.objectData.insurance_to_date) ||
        this.$helpers.isValidDate(this.objectData.requested_insurance_to_date)
      ) {
        let toast = useToast();
        toast.error("Detta objekt har redan ett upphörandedatum");
      } else this.showCancelModal = true;
    },
    async modalClosed(isCancelled) {
      this.showCancelModal = false;
      if (isCancelled) {
        await this.load();
      }
    },
    async getItem() {
      await axiosBase
        .get(`api/v1/insuranceobject/${this.$route.params.id}/`)
        .then(async (response) => {
          if (this.activeObjectType.id !== response.data["object_type"].id) {
            await this.$store.dispatch(
              "setActiveObjectType",
              response.data["object_type"].id
            );
          }
          this.objectData = response.data;
        })
        .catch(() => {});
    },
    getObjectName(response) {
      if (response.name) return response.name;
      else if (response.property_designation)
        return response.property_designation;
      return "";
    },
    getFieldValue(item, data) {
      if (data === null) return data;
      if (item.type === "currency") return this.$helpers.returnCurrency(data);
      if (item.type === "date") {
        return this.$helpers.isValidDate(data)
          ? new Date(data).toLocaleDateString("se-SV")
          : data;
      }
      if (typeof data === "object") {
        if (data.description) return data.description;
        if (data.username) return data.username;
        if (data.org_no) return `${data.name} - ${data.org_no}`;
      }
      return data;
    },
    async getStructure() {
      await axiosBase
        .get(
          `api/v1/fieldstructure/?object_type=${this.activeObjectType.id}&type=simple`
        )
        .then((response) => {
          this.columns = response.data;
        })
        .catch(() => {});
    },
    async getChangeHistory() {
      await axiosBase
        .get(
          `api/v1/changelog/?change_master__insurance_object=${this.$route.params.id}`
        )
        .then((response) => {
          this.changes = response.data;
        });
    },
    async getPdf() {
      window.print();
    },
    buildCompleteObjectInformation() {
      this.setInsuranceDescription(this.objectData);
      for (let section of this.columns) {
        for (let field of section.fields) {
          if (field.label)
            field.value = this.getFieldValue(
              field,
              this.objectData[field.name]
            );
        }
      }
      this.name = this.getObjectName(this.objectData);
      this.coord = this.$helpers.returnSplitCoord(this.objectData.coordinates);
      this.hasPendingChanges = this.objectData.changemaster.length > 0;
    },
    async load() {
      await this.getItem();
      await this.getStructure();
      this.buildCompleteObjectInformation();
      await this.getChangeHistory();
    },
  },
  async created() {
    await this.load();
  },
};
</script>

<style scoped>
@media print {
  .scrollable {
    overflow: visible !important;
    width: 100%;
  }
  .left-menu-container {
    display: none !important;
  }
  .collapse:not(.show) {
    display: block;
  }
  .fa-plus,
  .fa-minus {
    display: none;
  }
  .scrollable-print-changes {
    overflow: hidden !important;
    width: 100%;
  }
}
.list-group {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.left-menu-container .list-group {
  width: 60%;
}
.list-group-fullwidth {
  min-width: 100%;
}
button.ol-zoom-out {
  margin-top: -10px !important;
}
a.list-group-item.list-group-item-action {
  padding-left: 8px;
}
#scrolTop {
  margin-top: -17px;
}
.list-group-item {
  font-weight: 200;
}
.item-data {
  margin: 5px;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 7px;
  border-radius: 6px;
}
.item-text {
  overflow: visible;
  white-space: normal;
}
.ol-viewport {
  width: 79%;
  border-radius: 0.25rem;
  margin-top: 16px;
}
div.ol-overlaycontainer {
  width: 72%;
}
div.ol-layer {
  margin-top: 50px !important;
}
div.ol-zoom ol-unselectable ol-control {
  margin-top: 55px !important;
}
.ol-zoom.ol-unselectable.ol-control {
  margin-top: 23px;
}
.ol-zoom {
  padding-top: 11px;
}
a {
  color: #495057;
  text-decoration: none;
  cursor: pointer;
  margin-left: 14px;
  padding-right: 10px;
}
.list-group-item-action {
  justify-content: center;
  display: flex;
}
.scrollable {
  height: 70vh;
  overflow: auto;
}
i.posting {
  font-size: small;
  max-width: 300px;
}
.posting {
  font-weight: normal;
  max-width: 300px;
}
.list-group-flush {
  border-radius: 0;
  max-width: 80%;
}
.list-group-flush > .list-group-item {
  padding: 2px;
}
.fa-trash:before {
  color: #0176ba;
  padding-right: 10px;
}
.fa-database:before {
  color: #0176ba;
  padding-right: 10px;
}
.fa-exchange-alt:before {
  color: #0176ba;
  padding-right: 10px;
}
.fa-file-pdf:before {
  color: #0176ba;
  padding-right: 10px;
}
textarea.text-box {
  width: 400px;
  height: 150px;
  border: none;
  background: none;
  resize: none;
}
.color {
  color: #0176ba;
  font-weight: 600;
  padding-right: 0 !important;
}
.sidebar {
  margin-left: 15px;
  margin-top: 15px;
}
.overlay {
  width: 100%;
  background-color: #fff;
  border-color: rgba(223, 225, 229, 0);
  z-index: 2;
}
.left-menu-container {
  width: 100%;
  position: relative;
  display: inline-block;
}
.smallMap {
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
  left: 0;
  right: 0;
}
.section {
  border-radius: 6px;
  margin-bottom: 15px;
}
.section-header {
  border-bottom: 1px solid rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  background-color: #cbe5e8;
}
.section-header[aria-expanded="true"] .fa-plus {
  display: none;
}
.section-header[aria-expanded="false"] .fa-minus {
  display: none;
}
.section-title {
  padding: 15px;
  color: rgb(89, 89, 89);
  font-size: 1.4rem;
}
.section-body {
  padding: 15px;
  background-color: #e1eff0;
}
.list-group-divider {
  color: #e1eff0;
  margin: 3px 0 10px 0;
}
</style>
<style>
.smallMap #map {
  margin: 16px 0 0 0;
  height: 250px;
}
</style>
